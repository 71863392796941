// currencies
export const EUR = 'EUR';

// Response
export enum RESPONSE {
  success = 'SUCCESS',
  error = 'error',
  ok = 'ok'
}

export enum STATUS {
  internalServerError = 'INTERNAL_SERVER_ERROR',
  promocodeNotUnique = 'PROMO_CODE_VALUE_IS_NOT_UNIQUE',
  success = 'SUCCESS'
}

export enum ROLES {
  admin = 'ADMIN_BO',
  support = 'SUPPORT_BO',
  operator = 'OPERATOR_BO',
  customerSupport = 'CUSTOMER_SUPPORT'
}

export enum ENVIRONMENTS {
  dev = 'dev',
  prod = 'prod'
}