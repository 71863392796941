import { FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { Button, Divider, Form, Input, Popover } from 'antd';
import { useAppDispatch } from 'hooks/redux';
import { logIn } from 'store/slices/app.slice';

import styles from './AuthPage.module.scss';

const Authorization: FC = () => {
  const dispatch = useAppDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onFinish = async (values: { email: string, password: string }) => {
    if (!executeRecaptcha) {
      console.debug('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('authorization');

    const params = {
      email: values.email.toLowerCase(),
      password: values.password,
      token
    };

    dispatch(logIn(params));
  };

  // const onFinishFailed = (errorInfo: any) => {
  //   console.log('Failed:', errorInfo);
  // };

  const content = (
    <div style={{ width: 300 }}>
      <p>
        Если вы забыли пароль, обратитесь в службу клиентского сервиса, написав на почту{' '}
        <a href="mailto:support@gmonit.ru">support@gmonit.ru</a> с просьбой восстановить пароль
      </p>
    </div>
  );

  return (
    <>
      <Form
        name="basic"
        layout="vertical"
        size="large"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Почтовый адрес"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Введите корректный email'
            },
            {
              required: true,
              message: 'Введите ваш email'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <div className={styles.button}>
            <Button type="primary" htmlType="submit">
              Войти
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Divider />
      <div className={styles.links}>
        <Link to="/signIn">Нет аккаунта? Зарегистрируйтесь</Link>
        <Popover content={content} title="Забыли пароль?">
          <span className={styles.forgetLink}>Забыли пароль?</span>
        </Popover>
      </div>
    </>
  );
};

export default Authorization;
