import { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Result, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { confirmEmail, resetSuccessedRegistration } from 'store/slices/app.slice';

import styles from './EmailConfirmatiom.module.scss';

const EmailConfirmation: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isReceiveConfirmation, successedConfirmation, verificationErrorText } = useAppSelector(
    state => state.app
  );

  const token = location.search.replace('?token=', '');

  useEffect(() => {
    dispatch(resetSuccessedRegistration());
    dispatch(confirmEmail(token));
  }, [dispatch, token]);

  if (isReceiveConfirmation) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <a href="https://gmonit.ru/">
          <i className={styles.logo} />
        </a>
      </header>
      {successedConfirmation ? (
        <Result
          status="success"
          title="Поздравляем! Ваш почтовый адрес успешно подтверждён."
          extra={[
            <Link to="/" key="console">
              <Button type="primary">Войти</Button>
            </Link>
          ]}
        />
      ) : (
        <Result status="error" title={verificationErrorText} />
      )}
    </div>
  );
};

export default EmailConfirmation;
