import { FC, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { resetSuccessedRegistration } from 'store/slices/app.slice';
import { isProdEnv } from 'utils/app';
import Authorization from './Authorization';
import Registration from './Registration';

import styles from './AuthPage.module.scss';

interface AuthPageParams {
  isRegistration?: boolean;
}

const AuthPage: FC<AuthPageParams> = ({ isRegistration }) => {
  const dispatch = useAppDispatch();
  const { successedRegistration, isNeedReloadStartPage } = useAppSelector(state => state.app);

  useEffect(() => {
    if (isNeedReloadStartPage) {
      document.location.reload();
    }
  }, [isNeedReloadStartPage]);

  if (successedRegistration) {
    return (
      <div className={styles.page}>
        <header className={styles.header}>
          <a href="https://gmonit.ru/">
            <i className={styles.logo} />
          </a>
        </header>
        <Result
          status="success"
          title="Поздравляем! Вы успешно зарегистрировались. Проверьте вашу почту."
          extra={[
            <Link to="/" key="console">
              <Button type="primary" onClick={() => dispatch(resetSuccessedRegistration())}>
                На страницу авторизации
              </Button>
            </Link>
          ]}
        />
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <a href="https://gmonit.ru/">
          <i className={styles.logo} />
        </a>
      </header>
      <div className={styles.loginForm}>
        <h1>{!isRegistration ? 'Войти в личный кабинет' : 'Регистрация'}</h1>
        <GoogleReCaptchaProvider
          reCaptchaKey={
            isProdEnv
              ? '6Lcs9xgjAAAAAP_Of0o67FAkjyEXSytGby2k-1L1'
              : '6LdL8g4jAAAAAMcVrxZQrysnoOJw_4yssjliOEO9'
          }
          language="ru"
          container={{
            element: 'captchaContainer',
            parameters: {
              badge: 'inline'
            }
          }}
        >
          <div className={styles.form}>{!isRegistration ? <Authorization /> : <Registration />}</div>
          <div id="captchaContainer" className={styles.captcha} />
        </GoogleReCaptchaProvider>
      </div>
    </div>
  );
};

export default AuthPage;
