import { FC } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import PropTypes from 'prop-types';
import { store } from 'store';

dayjs.locale(ru);

const Providers: FC = ({ children }) => (
  <StoreProvider store={store}>
    <ConfigProvider locale={ruRU}>{children}</ConfigProvider>
  </StoreProvider>
);

Providers.propTypes = { children: PropTypes.element };

export default Providers;
