import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { AppstoreAddOutlined, FilePdfOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { logOut } from 'store/slices/app.slice';
import { userRoles } from 'types/app.types';
import { isProdEnv } from 'utils/app';

import styles from './MainLayout.module.scss';

const { Content, Footer, Sider } = Layout;

const MainLayout: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { userRole } = useAppSelector(state => state.app);
  const isSupport = userRole === userRoles.support;

  const jivoScript = () => {
    if (isSupport) {
      return null;
    }

    return isProdEnv ? (
      <script src="//code.jivo.ru/widget/0onngCVAFH" async />
    ) : (
      <script src="//code.jivo.ru/widget/xDCvi3T1YV" async />
    );
  };

  const menuItems = isSupport
    ? [
        {
          key: '/',
          icon: <AppstoreAddOutlined />,
          label: <NavLink to="/">Добавить кластер</NavLink>
        }
      ]
    : [
        {
          key: '/',
          icon: <UnorderedListOutlined />,
          label: <NavLink to="/">Ресурсы</NavLink>
        },
        {
          key: '/docs',
          icon: <FilePdfOutlined />,
          label: <NavLink to="/docs">Документы</NavLink>
        }
      ];

  return (
    <div className={styles.wrapper}>
      <Layout style={{ minHeight: '100vh' }}>
        <Helmet>
          {jivoScript()}
          <script>{`
          function jivo_onLoadCallback() {
            console.debug('Widget fully loaded');
            jivo_api.setContactInfo({
              email: window.localStorage.getItem('email')
            });
          }
        `}</script>
        </Helmet>
        <Sider collapsed={false} collapsedWidth="0">
          <h2 className={styles.sidebarTitle}>Личный кабинет</h2>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={[pathname]} items={menuItems} />
        </Sider>
        <Layout>
          <Content style={{ margin: '24px 16px 0' }}>
            <div className={styles.userActions}>
              <Link to="/" onClick={() => dispatch(logOut())}>
                Выйти
              </Link>
            </div>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <Outlet />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>GMonit © 2022 </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default MainLayout;
