import { ENVIRONMENTS  } from 'constants/app';

export const isLocalHost = () =>
  // Добавлен порт, так как есть локальное разворачивание, где нужна относительная ссылка
  window.location.host.includes('localhost:3000') || window.location.hostname.includes('127.0.0.1');

export const getEnv = () => {
  if (process.env.REACT_APP_ENV) {
    return process.env.REACT_APP_ENV;
  }
  if (isLocalHost()) {
    return 'localhost';
  }

  return ENVIRONMENTS.prod;
};

export const getAppVersion = () => process.env.REACT_APP_VERSION;

export const getAppBuildVersion = () => process.env.REACT_APP_BUILD_VERSION;

export const isProdEnv = process.env.REACT_APP_ENV === ENVIRONMENTS.prod;