import { FC, useEffect } from 'react';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getPackages } from 'store/slices/app.slice';
import ClastersTable from './ClastersTable/ClastersTable';
import Greeting from './Greeting/Greeting';

import styles from './Resources.module.scss';

const Resources: FC = () => {
  const dispatch = useAppDispatch();
  const { packages, isLoadingPackages } = useAppSelector(state => state.app);

  useEffect(() => {
    dispatch(getPackages());
  }, [dispatch]);

  if (isLoadingPackages) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return <div>{packages.length !== 0 ? <ClastersTable /> : <Greeting />}</div>;
};

export default Resources;
