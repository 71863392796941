import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { localStorageService } from 'servises/storage.service';
import { store } from 'store';
import { refreshToken } from 'store/slices/app.slice';

const baseURL = `${localStorageService.get('hostName')}/api`;

export const publicApi = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const setAxiosConfig = (baseURL: string) => {
  publicApi.defaults.baseURL = baseURL;
  api.defaults.baseURL = baseURL;
};

api.interceptors.request.use(
  async config => {
    const userInfo = JSON.parse(localStorage?.getItem("user") as string) || '';
    const accessToken = userInfo.accessToken || null;

    const currentDate = new Date();
    if (accessToken) {
      const tokenInfo: any = jwtDecode(accessToken);

      if (config?.headers) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }

      if (tokenInfo.exp * 1000 < currentDate.getTime()) {
        let token;
        await store.dispatch(refreshToken()).then(response => {
          token = response.payload.accessToken;
        });

        if (config?.headers) {
          config.headers.authorization = `Bearer ${token}`;
        }
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

// export const configureAxios = () => {
//   api.interceptors.request.use(
//     config => {
//       if (authService.isLoggedIn()) {
//         const callback = () => {
//           config.headers= {
//             'Authorization': `Bearer ${authService.getToken()}`
//           };
//           return Promise.resolve(config);
//         };
//         return authService.updateToken(callback);
//       }
//   });
// };

// api.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// );

export const setParams = (rawParams: any) => {
  let params = {};

  for (const key in rawParams) {
    if (rawParams[key] !== undefined) {
      params = { ...params, [key]: rawParams[key] };
    }
  }

  const config = {
    params: {
      ...params
    }
  };

  return config;
};
