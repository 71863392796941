import { FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { Button, Divider, Form, Input, message } from 'antd';
import { useAppDispatch } from 'hooks/redux';
import { signUp } from 'store/slices/app.slice';

import styles from './AuthPage.module.scss';

const Registration: FC = () => {
  const dispatch = useAppDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onFinish = async (values: { email: string, password: string, passwordCheck: string }) => {
    const { email, password, passwordCheck } = values;

    if (!executeRecaptcha) {
      console.debug('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('registration');

    if (password !== passwordCheck) {
      message.error('Пароли не совпадают');
    } else {
      const params = {
        email: email.toLowerCase(),
        password,
        token
      };

      dispatch(signUp(params));
    }
  };

  return (
    <>
      <Form
        name="registration"
        layout="vertical"
        size="large"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Почтовый адрес"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Введите корректный email'
            },
            {
              required: true,
              message: 'Введите ваш email'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[
            { required: true, message: 'Введите пароль' },
            {
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}$/gm,
              message:
                'Пароль должен быть не короче 12 символов, содержать буквы в верхнем и нижнем регистрах и минимум 1 цифру'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Повторный ввод пароля"
          name="passwordCheck"
          rules={[
            { required: true, message: 'Введите пароль повторно' },
            {
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}$/gm,
              message:
                'Пароль должен быть не короче 12 символов, содержать буквы в верхнем и нижнем регистрах и минимум 1 цифру'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <div className={styles.button}>
            <Button type="primary" htmlType="submit">
              Зарегистрироваться
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Divider />
      <Link to="/">Уже зарегистрированы? Войдите в личный кабинет</Link>
    </>
  );
};

export default Registration;
