import { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { isEmpty } from 'lodash';
import { setAxiosConfig } from 'servises/api.service';
import { localStorageService } from 'servises/storage.service';
import { initUser } from 'store/slices/app.slice';
import { userRoles } from 'types/app.types';
import { getEnv } from 'utils/app';
import AddClaster from 'pages/AddClaster/AddClaster';
import AuthPage from 'pages/AuthPage/AuthPage';
import Docs from 'pages/Docs/Docs';
import EmailConfirmation from 'pages/EmailConfirmation/EmailConfirmation';
import Resources from 'pages/Resources/Resources';

import 'css/vars.css';
import 'antd/dist/antd.css';
import 'css/ovverideAntd.css';

const App: FC = () => {
  const dispatch = useAppDispatch();

  const { isAppLoading, userRole } = useAppSelector(state => state.app);

  const user = JSON.parse(localStorage?.getItem("user") as string) || {};
  const isAuth = !isEmpty(user);

  const isSupport = userRole === userRoles.support;

  useEffect(() => {
    dispatch(initUser());
  }, [dispatch]);

  useEffect(() => {
    let path;
    switch (getEnv()) {
      case 'localhost':
        path = './configuration/api_local';
        break;
      default:
        path = './configuration/api_url';
        break;
    }
    fetch(path)
      .then((response: any) =>
        response.ok
          ? response.text()
          : console.error(
              `Ошибка при получении адреса API сервера ${response.status} : ${response.statusText}`
            )
      )
      .then(response => {
        localStorageService.set('hostName', `https://${response}`);
        setAxiosConfig(`https://${response}/api`);
      });
  }, []);

  if (isAppLoading) {
    return <div />;
  }

  return (
    <Routes>
      {!isAuth && (
        <>
          <Route index element={<AuthPage />} />
          <Route path="signIn" element={<AuthPage isRegistration />} />
          <Route path="/confirm" element={<EmailConfirmation />} />
        </>
      )}
      {isAuth && (
        <Route path="/" element={<MainLayout />}>
          <Route path="signIn" element={<Navigate to="/" replace />} />
          {isSupport ? <Route index element={<AddClaster />} /> : <Route index element={<Resources />} />}

          <Route path="docs" element={<Docs />} />
        </Route>
      )}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;
