import { FC, useEffect, useState } from 'react';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { editComment, getPackages } from 'store/slices/app.slice';
import { TableDataType } from 'types/app.types';

import styles from './ClastersTable.module.scss';

const ClastersTable: FC = () => {
  const dispatch = useAppDispatch();

  const { packages } = useAppSelector(state => state.app);

  const [rowsWithInput, setRowsWithInput] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const pageNumber = Number(localStorage?.getItem('page'));
    setPage(pageNumber !== 0 ? pageNumber : 1);
  }, []);

  const saveComment = (id: string, newComment: string) => {
    const params = {
      id,
      comment: newComment
    };

    setRowsWithInput(rowsWithInput.filter(el => el !== id));
    dispatch(editComment(params)).then(() => {
      dispatch(getPackages());
    });
  };

  const handleChangePage = (newPage: number) => {
    localStorage.setItem('page', String(newPage));
    setPage(newPage);
  };

  const columns: ColumnsType<TableDataType> = [
    {
      title: 'Ссылка на Grafana',
      dataIndex: 'grafanaLink',
      key: 'grafanaLink',
      render: grafanaLink => (
        <a type="link" href={grafanaLink}>
          Grafana
        </a>
      ),
      width: '20%'
    },
    {
      title: 'Стоимость заказа',
      dataIndex: 'price',
      key: 'price',
      width: '20%'
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      key: 'endDate',
      width: '20%'
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      width: '40%',
      render: (comment, row) => {
        if (rowsWithInput.includes(row.id)) {
          let newComment: string = comment;

          const setNewComment = (e: any) => {
            newComment = e.target.value;
          };
          return (
            <div className={styles.setComment}>
              <Input defaultValue={comment} onChange={setNewComment} maxLength={250} />
              <Button icon={<CheckOutlined />} onClick={() => saveComment(row.id, newComment)} />
            </div>
          );
        }
        return (
          <div className={styles.setComment}>
            <span className={styles.comment}>{comment}</span>
            <Button
              className={styles.editBtn}
              icon={<EditOutlined />}
              onClick={() => setRowsWithInput([...rowsWithInput, row.id])}
            />
          </div>
        );
      }
    }
  ];

  const dataSource = packages?.map((item: any) => ({
    ...item,
    key: item.id
  }));

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={{
        current: page,
        onChange: newPage => handleChangePage(newPage)
      }}
    />
  );
};

export default ClastersTable;
