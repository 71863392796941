class StorageService {
  constructor(storage) {
    this.storage = storage;
  }

  get(key) {
    try {
      return JSON.parse(this.storage.getItem(key));
    } catch (error) {
      console.error(error);
      return this.storage.getItem(key);
    }
  }

  set(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    this.storage.removeItem(key);
  }

  has(key) {
    return this.storage.getItem(key) !== undefined && this.storage.getItem(key) !== null;
  }

  clear() {
    this.storage.clear();
  }
}

export const localStorageService = new StorageService(localStorage);
export const sessionStorageService = new StorageService(sessionStorage);
