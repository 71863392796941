export enum userRoles {
  user = 'ROLE_USER',
  support = 'ROLE_SUPPORT'
}

export interface TableDataType {
  key: React.Key;
  id: string;
  link: any;
  cost: string;
  dateTill: string;
}

export interface IRoutes {
  path: string;
  PageComponent: React.FunctionComponent;
  requiredRoles?: string[];
}
export interface IColumns {
  title: string;
  dataIndex: string;
  key: string;
}

export interface IBreadcrumb {
  path?: string;
  breadcrumbName?: string;
}

export interface IMenu {
  id: number;
  tabTitle: string;
  requiredRoles?: string[]
  items: {
    id: number,
    title: string,
    link: string,
    requiredRoles?: string[]
  }[];
}
