import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import jwtDecode from "jwt-decode";
import { isEmpty } from 'lodash';
import { api, publicApi } from 'servises/api.service';
import { localStorageService } from 'servises/storage.service';

interface ILogin {
  email: string;
  accessToken: string;
  refreshToken: string;
}

export const logIn = createAsyncThunk(
  'app/login',
  async (params: { email: string, password: string }) => {
    const response = await publicApi.post<ILogin>("/login", params);

    return response.data;
  }
);

export const signUp = createAsyncThunk(
  'app/signUp',
  async (params: { email: string, password: string }) => {
    const response = await publicApi.put("/signup", params);

    return response.data;
  }
);

export const refreshToken = createAsyncThunk(
  'app/refreshToken',
  async (_, { rejectWithValue }) => {
    const user = JSON.parse(localStorage?.getItem("user") as string) || {};

    if (user.refreshToken !== '') {
      const response = await publicApi.get("/refreshToken", {
        headers: { authorization: `Bearer ${user.refreshToken}` },
      });
      return response.data;
    }

    return rejectWithValue({});
  }
);

export const getPackages = createAsyncThunk(
  'app/getPackages',
  async () => {
    const response = await api.get("/packages");

    return response.data;
  }
);

export const getUsers = createAsyncThunk(
  'app/getUsers',
  async () => {
    const response = await api.get("/allUsers");

    return response.data;
  }
);

export const addPackage = createAsyncThunk(
  'app/addPackage',
  async (params: any) => {
    const response = await api.post("/package", params);

    return response.data;
  }
);

export const editComment = createAsyncThunk(
  'app/editComment',
  async (params: { id: string, comment: string }) => {
    const response = await api.post(`/package/${params.id}`, { comment: params.comment }, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  }
);

export const confirmEmail = createAsyncThunk(
  'app/confirmEmail',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await publicApi.get("/signupConfirm", {
        headers: { authorization: `Bearer ${token}` }
      });

      return response.data;

    } catch (err: any) {
      return rejectWithValue(err.response.data.status);
    }
  }
);

interface AppState {
  isAuth: boolean;
  isAppLoading: boolean;
  isAdmin: boolean;
  userRole: string;
  packages: [];
  isLoadingPackages: boolean;
  isPackageSaving: boolean;
  users: { email: string; id: string; roleName: string[]; }[],
  user: any,
  isReceiveConfirmation: boolean;
  successedConfirmation: boolean;
  successedRegistration: boolean;
  verificationErrorText: string;
  isNeedReloadStartPage: boolean;
}

const initialState = {
  isAppLoading: false,
  isAuth: false,
  isAdmin: false,
  userRole: 'ROLE_USER',
  users: [],
  packages: [],
  isLoadingPackages: false,
  isPackageSaving: false,
  user: [],
  isReceiveConfirmation: false,
  successedConfirmation: true,
  successedRegistration: false,
  verificationErrorText: '',
  isNeedReloadStartPage: false
} as AppState;

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initUser: state => {
      const user = JSON.parse(localStorage?.getItem("user") as string) || {};

      state.user = user;
      state.isAuth = !isEmpty(user);
      if (user.accessToken) {
        const tokenInfo: any = jwtDecode(user.accessToken);
        state.userRole = tokenInfo.scope;
        localStorage?.setItem("email", user.email);
      };
    },
    logOut: state => {
      state.isAuth = !state.isAuth;
      localStorageService.remove('user');
      localStorageService.remove('page');
      state.isNeedReloadStartPage = true;
    },
    toggleAdminRole: state => {
      state.isAdmin = !state.isAdmin;
    },
    resetSuccessedRegistration: state => {
      state.successedRegistration = false;
    }
  },
  extraReducers: builder => {
    // login
    builder.addCase(logIn.pending, state => {
      // state.isLevelsLoading = true;
    });
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.isAuth = true;
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage?.setItem("email", action.payload.email);
      state.user = action.payload;

      const tokenInfo: any = jwtDecode(action.payload.accessToken);
      state.userRole = tokenInfo.scope;
    });
    builder.addCase(logIn.rejected, state => {
      state.isAuth = false;
      message.error('Не удалось авторизоваться. Проверьте введеные email и пароль');
    });

    // signUp
    builder.addCase(signUp.pending, state => {
      // state.isLevelsLoading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.successedRegistration = true;
    });
    builder.addCase(signUp.rejected, state => {
      state.successedRegistration = false;
      message.error('Не удалось зарегистрироваться. Повторите попытку или обратитесь в службу поддержки');
    });

    // refresh token
    builder.addCase(refreshToken.pending, state => {
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorageService.set('email', action.payload.email);
      state.user = action.payload;
    });
    builder.addCase(refreshToken.rejected, state => {
      state.isAuth = !state.isAuth;
      localStorageService.remove('user');
      localStorageService.remove('page');
      state.isNeedReloadStartPage = true;
    });

    // get users
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    // get packages
    builder.addCase(getPackages.pending, state => {
      state.isLoadingPackages = true;
    });
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.packages = action.payload;
      state.isLoadingPackages = false;
    });
    builder.addCase(getPackages.rejected, state => {
      state.isLoadingPackages = false;
      message.error('Не удалось загрузить данные по действующим кластерам');
    });

    // add package
    builder.addCase(addPackage.pending, state => {
      state.isPackageSaving = true;
    });
    builder.addCase(addPackage.fulfilled, (state) => {
      message.success('Данные успешно сохранены');
      state.isPackageSaving = false;
    });
    builder.addCase(addPackage.rejected, state => {
      state.isPackageSaving = false;
      message.error('Не удалось сохранить данные');
    });

    // confirm email
    builder.addCase(confirmEmail.pending, state => {
      state.isReceiveConfirmation = true;
    });
    builder.addCase(confirmEmail.fulfilled, (state) => {
      state.successedConfirmation = true;
      state.isReceiveConfirmation = false;
    });
    builder.addCase(confirmEmail.rejected, (state, action) => {
      state.isReceiveConfirmation = false;
      state.successedConfirmation = false;

      switch (action.payload) {
        case 401:
          state.verificationErrorText = 'Ссылка больше недействительна, для подтверждения почты обратитесь в службу клиентской поддержки support@gmonit.ru';
          break;
        case 503:
          state.verificationErrorText = 'При подтверждении почты возникла непредвиденная ошибка. Попробуйте подтвердить почту, перейдя по ссылке из письма, немного позднее';
          break;
        default:
          state.verificationErrorText = 'Ошибка подтверждения почтового адреса.  Попробуйте подтвердить почту, перейдя по ссылке из письма, немного позднее или обратитесь в службу клиентской поддержки support@gmonit.ru';
          break;
      }
    });
  }
});

export const { logOut, toggleAdminRole, initUser, resetSuccessedRegistration } = appSlice.actions;

export default appSlice.reducer;