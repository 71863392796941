/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
// import { Card, Col, Row, Space } from 'antd';
import { Space } from 'antd';

// import styles from './Docs.module.scss';

const Docs: FC = () => (
  <>
    <h2>Скачать:</h2>
    <Space align="baseline">
      <FileTextOutlined style={{ fontSize: '20px' }} />
      <a target="_blank" href="./assets/docs/website-offer_license-agreement.pdf">
        <span>Договор-оферта на оказание услуг</span>
      </a>
    </Space>
    <br />
    <br />
    <br />
    {/* <h2>Тарифы:</h2>
    <div className="site-card-wrapper">
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Тариф Lite" bordered={false}>
            <ul>
              <li>Память: 1 Gb</li>
              <li>CPU: 2,4 GHz</li>
              <li>Хранилище: 128 Gb</li>
            </ul>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Тариф Medium" bordered={false}>
            <ul>
              <li>Память: 2 Gb</li>
              <li>CPU: 2,4 GHz</li>
              <li>Хранилище: 512 Gb</li>
            </ul>
          </Card>
        </Col>
      </Row>
    </div> */}
  </>
);

export default Docs;
