import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import Providers from 'providers/Providers';
import { getAppBuildVersion, getAppVersion, getEnv } from 'utils/app';
import App from './components/App/App';

console.debug(
  `%cGMonit: ${getAppVersion()}.${getEnv()} (build ${getAppBuildVersion()})`,
  'font: bold 1em Arial;'
);

ReactDOM.render(
  <Router>
    <Providers>
      <App />
    </Providers>
  </Router>,
  document.getElementById('root')
);
