import { FC, useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { addPackage, getUsers } from 'store/slices/app.slice';
import DatePicker from 'components/Common/antDesign/DatePicker';

// import styles from './AddClaster.module.scss';

const AddClaster: FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { users, isPackageSaving } = useAppSelector(state => state.app);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const handleSendForm = (values: any) => {
    const userId = users.find(user => user.email === values.email)?.id;

    const params = {
      userId,
      email: values.email,
      grafanaLink: values.grafanaLink,
      endDate: dayjs(values.period).format('YYYY-MM-DD'),
      price: values.price,
      comment: values.comment || ''
    };

    dispatch(addPackage(params)).then(() => {
      form.resetFields();
    });
  };

  return (
    <>
      <h1>Форма добавление кластера</h1>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        wrapperCol={{ span: 10 }}
        initialValues={{ remember: true }}
        onFinish={handleSendForm}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="Клиент" name="email" rules={[{ required: true, message: 'Required' }]}>
          <Select
            showSearch
            placeholder="Выберите клиента"
            optionFilterProp="children"
            // onChange={onChange}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={users.map(user => ({
              value: user.email,
              label: user.email
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Ссылка на Grafana"
          name="grafanaLink"
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Стоимость заказа" name="price" rules={[{ required: true, message: 'Required' }]}>
          <Input maxLength={250} />
        </Form.Item>

        <Form.Item label="Период" name="period" rules={[{ required: true, message: 'Required' }]}>
          <DatePicker style={{ width: 150 }} format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item label="Комментарий (не обязательно)" name="comment">
          <Input maxLength={250} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isPackageSaving}>
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddClaster;
