import { FC } from 'react';
import { Tooltip } from 'antd';

import styles from './Greeting.module.scss';

const Greeting: FC = () => (
  <div className={styles.wrapper}>
    <p>Добро пожаловать в личный кабинет!</p>
    <p>
      У вас пока нет ни одной запущенной инсталляции. Для начала использования GMonit следуйте простой
      инструкции:
    </p>
    <ol>
      <li>
        С помощью наших специалистов сформируйте запрос на разворачивание инсталляции GMonit. Это можно
        сделать в чате внизу экрана.
      </li>
      <li>Оператор задаст вам несколько уточняющих вопросов и подготовит счёт на оплату лицензии.</li>
      <li>Оплатите счёт любым удобным способом (в том числе по QR-коду в приложении вашего банка).</li>
      <li>
        После получения подтверждения оплаты мы развернём для вас{' '}
        <Tooltip title="У вас будет собственная инфраструктура и все ваши данные будут храниться отдельно от данных остальных пользователей">
          <span className={styles.tooltip}>индивидуальную версию</span>
        </Tooltip>{' '}
        GMonit.
      </li>
      <li>
        На адрес электронной почты, указанный при регистрации, мы вышлем всю необходимую информацию для начала
        работы с GMonit. А также, она появится в вашем личном кабинете.
      </li>
    </ol>

    <div className={styles.message}>
      Для начала работы напишите запрос в чате <i className={styles.jivo} /> внизу экрана.
    </div>
  </div>
);

export default Greeting;
